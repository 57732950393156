import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['burgerButton', 'mobileNavigation']
  static classes = ['open', 'lockBody']

  toggleMobileMenu () {
    this._scrollTop()
    this._toggleBodyLock()
    this._toggleBurgerClass()
    this._toggleMobileNavigation()
  }

  _toggleBurgerClass () {
    this.burgerButtonTarget.classList.toggle(this.openClass)
  }

  _toggleMobileNavigation () {
    this.mobileNavigationTarget.classList.toggle(this.openClass)
  }

  _toggleBodyLock () {
    document.body.classList.toggle(this.lockBodyClass)
  }

  _scrollTop () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
