import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['toggle']

  connect () {
    this.class = this.hasToggleClass ? this.toggleClass : 'hidden'
  }

  toggle (event) {
    const identifier = event.params.identifier !== undefined ? event.params.identifier : event.currentTarget.value

    if (event.currentTarget.tagName === 'SELECT') {
      [...event.currentTarget.options].map(o => o.value).forEach(function (value) {
        this.element.querySelectorAll(`[data-reveal-identifier*="${value}"]`).forEach(function (item) {
          item.classList.add(this.class)
        }.bind(this))
      }.bind(this))
    }

    if (event.currentTarget.tagName === 'INPUT') {
      if (event.currentTarget.type === 'radio') {
        this.element.querySelectorAll(`[name="${event.currentTarget.getAttribute('name')}"]`).forEach(function (radio) {
          this.element.querySelectorAll(`[data-reveal-identifier*="${radio.value}"]`).forEach(function (item) {
            item.classList.add(this.class)
          }.bind(this))
        }.bind(this))
      }
    }

    this.element.querySelectorAll(`[data-reveal-identifier*="${identifier}"]`).forEach(function (item) {
      item.classList.toggle(this.class)
    }.bind(this))
  }
}
