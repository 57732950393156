import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'tab']

  toggle (event) {
    this.buttonTargets.forEach((button, index) => {
      const currentButton = button === event.currentTarget
      button.tabIndex = currentButton ? 0 : -1
      button.setAttribute('aria-selected', currentButton ? 'true' : 'false')
      this.tabTargets[index].setAttribute('aria-selected', currentButton ? 'true' : 'false')
    })
  }
}
