import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js'

export default class extends Controller {
  static values = {
    options: Object
  }

  connect () {
    this.tippy = tippy(this.element, {
      theme: 'light-border',
      interactive: true,
      allowHTML: true,
      maxWidth: 600,
      ...this.optionsValue
    })
  }

  disconnect () {
    this.tippy.destroy()
  }
}
