import { Dropdown } from 'tailwindcss-stimulus-components'

export default class extends Dropdown {
  _show (cb) {
    this.menuTarget.classList.add('invisible')
    this.menuTarget.classList.remove(this.toggleClass)

    setTimeout(
      () => {
        this.menuTarget.classList.remove('invisible')
        if (this.hasButtonTarget) {
          this.buttonTarget.setAttribute('aria-expanded', 'true')
        }
        this._enteringClassList[0].forEach(
          klass => {
            this.menuTarget.classList.add(klass)
          }
        )

        this._activeClassList[0].forEach(klass => {
          this.activeTarget.classList.add(klass)
        })
        this._invisibleClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
        this._visibleClassList[0].forEach(klass => {
          this.menuTarget.classList.add(klass)
        })
        setTimeout(
          () => {
            this._enteringClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
          },
          this.enterTimeout[0]
        )

        if (typeof cb === 'function') cb()
      }
    )
  }
}
