import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    bodyClass: { type: String, default: 'preload' }
  }

  removeBodyClass () {
    document.body.classList.remove(this.bodyClassValue)
  }
}
